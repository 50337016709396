<template>
    <div class="about-bg">
		<div class="content-bg-overlay">
		</div>
	</div>
	<div class="content-container-pur center">
		<h1 class="main-h1">ABOUT ME</h1>
		<h2 class="m-t-5em">WEB DEVELOPER, DESIGNER, DIGITAL MARKETER</h2>
		<div class="content-container-div-thin-80"></div>
		<div class="content-container-sub-100-pur">
		<div class="content-container-rnd-50 inline-block v-middle m-l-15">
		</div>
		<p class="inline-block w-50 v-middle">I am a web developer and digital marketer. I will find the perfect solution to make your online presence achieve your vision. I work with modern CMS systems, libraries, and frameworks but I will only use the technologies that are necessary for your project in order to avoid code-bloat.<br><br>To speak of my skills in increasing your online visibility... Allowing me to use my expertise for your social media, SEO, and online advertising campaigns will give your competition nightmares.<br><br>If your project works with large amounts of data or needs dashboards to be made, I can handle all of the frontend and backend tasks that will be necessary. I can even optimize the database if necessary.<br><br>I perform graphics design tasks using Adobe Illustrator and Adobe Photoshop. I can create logos, modify photos, and complete other graphical tasks you may need.<br><br>I am a rare all-in-one package and I have learned from the best by working with some of the most successful firms around the globe for over seven years.</p>
	</div>
	<h2 class="center">TECH STACK</h2>
		<div class="content-container-div-thin-80"></div>
		<div class="content-container-icons">
			<img class="icon-tech" src="images/icon-html5-css.svg" alt="HTML5-CSS" title="HTML5 & CSS">
			<img class="icon-tech" src="images/icon-javascript.svg" alt="JavaScript" title="JavaScript">
			<img class="icon-tech" src="images/icon-php.svg" alt="PHP" title="PHP">
			<img class="icon-tech" src="images/icon-csharp.svg" alt="C#" title="C#">
			<img class="icon-tech" src="images/icon-dotnet.svg" alt="Microsoft .NET" title="Microsoft .NET">
			<img class="icon-tech" src="images/icon-nodejs.svg" alt="Node.js" title="Node.js">
			<img class="icon-tech" src="images/icon-mysql.svg" alt="MySQL" title="MySQL">
			<img class="icon-tech" src="images/icon-miscrosoftsql.svg" alt="MSSQL" title="MSSQL">
			<img class="icon-tech" src="images/icon-angular.svg" alt="AngularJS" title="AngularJS">
			<img class="icon-tech" src="images/icon-reactjs.svg" alt="ReactJS" title="ReactJS">
			<img class="icon-tech" src="images/icon-vuejs.svg" alt="VueJS" title="VueJS">
			<img class="icon-tech" src="images/icon-apache.svg" alt="VueJS" title="VueJS">
			<img class="icon-tech" src="images/icon-nginx.svg" alt="Nginx" title="Nginx">
			<img class="icon-tech" src="images/icon-iis.svg" alt="ChartJS" title="ChartJS">
			<img class="icon-tech" src="images/icon-microsoftazure.svg" alt="Laravel" title="Laravel">
			<img class="icon-tech" src="images/icon-wordpress.svg" alt="WordPress" title="WordPress">
			<img class="icon-tech" src="images/icon-chartjs.svg" alt="ChartJS" title="ChartJS">
			<img class="icon-tech" src="images/icon-laravel.svg" alt="Laravel" title="Laravel">
		</div>
	</div>
</template>

<script>
export default {
  name: 'AboutComponent',
  props: {
    msg: String
  }
}
</script>
<style>
</style>
