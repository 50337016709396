<template>
    <div class="work-bg">
	<div class="content-bg-overlay">
		<!--<div onclick="workScroll();" id="btnWork" class="btn-circle-container pointer">
				<i class="fas fa-arrow-down"></i> OLD CODE BE HERE, HAR HAR HAR!
			</div>-->
		</div>
	</div>
	<div id="workID" class="content-container-pur center">
		<h1 class="main-h1">WORK</h1>
		<h2 class="center">RECENT DEVELOPMENT PROJECTS</h2>
		<div class="content-container-div-thin-80"></div>
		<div class="content-container-sub-100-pur">
            <h3>FINTECH</h3>
            <p>Introduction/Summary: This application had been under development for about 8 years when our team began working on it. The application itself had an extreme amount of technical debt, major performance issues, and some infinite recursion issues that literally brought down the entire server. We were hired to resolve these issues.            </p>
            <p>The Problems: First, performance was abysmal. For many clients, reports were taking over an hour to complete their calculations while certain parts of the applications ran into infinite recursion issues that literally shut down the entire server, sometimes causing up to 100 users to instantly lose service. Some parts of the application could not even render their UI due to low performance.
            </p>
            <p>The Solutions: Profiling was run on the application in order to assist with diagnosing the problematic areas in the code. Profiling was also run on the database because there were also many large several-thousand-lines stored procedures that were not performant as well. Pagination was also introduced to some pages since they were trying to return thousands of large complex UI objects due to the data they were pulling from the database. Virtualization of the DOM was also introduced in order to reduce the memory usage within the browser in order to accommodate the display of more records in the UI upon request. This also dramatically increased the UI performance of the reports. Many reports that were taking over an hour to calculate for were brought down to running in less than one second after our changes were implemented.
            </p>
        </div>
        <div>
            <h3>MEDICAL</h3>
            <p>This application was meant to assist the research team in the development of their pharmaceutical drugs. The program needed to offer them the ability to visually compile 3D molecular models and data relating to them. It needed to work on a touch screen, mobile phones, laptops, and desktop machines.
            </p>
            <p>Three.js was used for rendering 3D objects within this application. Additional logic was added in order to allow the live manipulation of onscreen 3D objects using a touch screen or mouse. Mathematical and organic equations could also be entered into the application in order to carry out chemical reactions, display the sequence of the reaction, and modify molecules as needed.
            </p>
            <p>Performance was also taken into consideration and DOM virtualization was implemented in the application in order to make it memory-efficient for the browser. Video conferencing features were also added on at the last minute during the later half of development so the team could fully operate remotely within their own digital ecosystem.</p>
        </div>
	</div>
</template>

<script>
export default {
  name: 'WorkComponent',
  props: {
    msg: String
  }
}
</script>
<style>
</style>
