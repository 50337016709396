// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import MainComponent from './components/MainComponent.vue';
import WorkComponent from './components/WorkComponent.vue';
import AboutComponent from './components/AboutComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import NotFoundComponent from './components/404Component.vue'; // Import the 404 component
import App from './App.vue'; // Import the App component

const routes = [
  { path: '/', component: MainComponent },
  { path: '/work', component: WorkComponent },
  { path: '/about', component: AboutComponent },
  { path: '/contact', component: ContactComponent },
  { path: '/:pathMatch(.*)*', component: NotFoundComponent } // Catch-all route
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

// Navigation guards to show and hide loader
router.beforeEach((to, from, next) => {
  App.methods.showLoader();
  next();
});

router.afterEach(() => {
  App.methods.hideLoader();
});

export default router;