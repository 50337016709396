<template>
    <div>
      <div class="bg-contact"></div>
      <div class="content-contact">
        <div class="contact-left">
          <h2 class="contact-h2">Contact</h2>
          <div class="contact-left-line"></div>
        </div>
        <div class="contact-right">
          <div class="contact-form">
            <form id="contactForm" class="contact" action="https://pur-code.com/php/pur.php" method="post">
              <label>Name</label>
              <input required maxlength="30" class="contact-name" name="contact-name" type="text">
              <label>Email</label>
              <input required maxlength="30" class="contact-email" name="contact-email" type="text">
              <label>Message</label>
              <textarea required maxlength="300" class="contact-message" name="contact-message" id="textInput" v-model="message" @input="textareaLengthCheck"></textarea>
              <p>Remaining characters: <span id="countTxt">{{ charactersLeft }}</span>/300</p>
              <button type="submit" name="submit" value="Submit" class="contact-btn-btn"><span class="contact-btn">Send</span></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactComponent',
    data() {
      return {
        message: '',
        charactersLeft: 300
      };
    },
    methods: {
      textareaLengthCheck() {
        this.charactersLeft = 300 - this.message.length;
      }
    }
  }
  </script>
  
  <style>
  /* Your styles here */
  </style>  