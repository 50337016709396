<template>
  <div>
    <div id="loaderOverlay" v-if="isLoading" class="loader">
      <div class="spinner">
        <p class="loader-p">Loading<span>.</span><span>.</span><span>.</span></p>
      </div>
    </div>
    <div id="burgerID" class="burger" @click="toggleMenu" :class="{ open: isMenuOpen }">
      <mark class="burger-mark-1"></mark>
      <mark class="burger-mark-2"></mark>
      <mark class="burger-mark-3"></mark>
    </div>
    <div id="javaMenu" class="menu" :class="{ open: isMenuOpen }">
      <ul>
        <li><router-link @click="toggleMenu()" to="/">Main</router-link><div class="menu-line"></div></li>
        <li><router-link @click="toggleMenu()" to="/work">Work</router-link><div class="menu-line"></div></li>
        <li><router-link @click="toggleMenu()" to="/about">About</router-link><div class="menu-line"></div></li>
        <li><router-link @click="toggleMenu()" to="/contact">Contact</router-link><div class="menu-line"></div></li>
      </ul>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isMenuOpen: false,
      isLoading: true
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    showLoader() {
      this.isLoading = true;
    },
    hideLoader() {
      const loaderVarA = document.getElementById("loaderOverlay");
      loaderVarA.classList.add("loaderFade");
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  },
  mounted() {
    this.hideLoader();
  }
}
</script>

<style>
.loaderFade {
  opacity: 0;
  transition: opacity 1s ease-out;
}
</style>