<template>
  <div class="bg-main"></div>
  <div class="content-main">
    <div class="main-container">
    <h1 class="main-h1">Pur-Code</h1>
    <h2 class="main-h2">Web Development and Design</h2>
        <span class="main-work-btn"><router-link to="/work">Work</router-link></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainComponent',
  props: {
    msg: String
  }
}
</script>
<style>
</style>
