  <template>
    <div class="content-main">
    <div class="main-container">
<h1 class="main-h1">404 Error</h1>
<h2 class="main-h2">The page you tried to reach does not exist, oh my!</h2>
        <span class="main-work-btn"><router-link to="/">Main</router-link></span>
</div>
    </div>
</template>

<script>
export default {
  name: '404Component',
  props: {
    msg: String
  }
}
</script>
<style>
</style>
